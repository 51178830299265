import { computed, ComputedRef } from 'vue';
import { Course, CourseInstance, Graph, Organization, Offer } from 'schema-dts';
import { EducationResource } from '../../../api/educationResources';
import { useHeadRaw } from '@vueuse/head';

const snykOrganization: Organization = {
  name: 'Snyk Ltd',
  '@id': 'https://snyk.io/#organization',
  '@type': 'Organization',
};

const hasCourseInstance = [
  {
    '@type': 'CourseInstance',
    courseMode: ['Online'],
    courseWorkload: 'PT30M',
  } as CourseInstance,
];

const offers = [
  {
    '@type': 'Offer',
    category: 'Free',
  } as Offer,
];

export const useCatalogStructuredData = (
  educationResources: ComputedRef<EducationResource[]>,
) => {
  useHeadRaw(
    computed(() => {
      return {
        script: educationResources.value.map(
          ({
            attributes: { name, description, tags, seo_title, date_published },
          }: EducationResource) => {
            const graph = [];

            const seoKeywords = tags.find(
              (x) => x.tag_type === 'seo_keywords',
            )?.tag_values;

            const course: Course = {
              //Required
              '@type': 'Course',
              description,
              name: seo_title || name,
              provider: snykOrganization,
              hasCourseInstance,
              offers,
              //Optional
              author: snykOrganization,
              inLanguage: 'en-US',
              ...(seoKeywords && { keywords: seoKeywords.join(',') }),
              ...(date_published && { datePublished: date_published }),
              ...(seo_title && { headline: seo_title }),
            };

            graph.push(course);

            const result: Graph = {
              '@context': 'https://schema.org',
              '@graph': graph,
            };

            return {
              type: 'application/ld+json',
              innerHTML: JSON.stringify(result),
            };
          },
        ),
      };
    }),
  );
};
export default useCatalogStructuredData;
