<template>
  <div>
    <div v-if="$slots.selection" aria-hidden="true" :class="selectionClasses">
      <slot name="selection" />
    </div>
    <BaseLayoutGap
      v-if="isActive"
      direction="column"
      size="medium"
      class="step_wrapper"
    >
      <slot />
      <div class="actions">
        <slot name="actions" />
        <BaseButton
          v-if="onSubmit"
          :disabled="!canProgress || submitInProgress"
          variant="primary"
          data-snyk-test="Stepper: Final"
          @click="onSubmit"
          >Submit
        </BaseButton>
        <BaseButton
          v-if="nextStep && !onSubmit"
          variant="primary"
          :disabled="!canProgress"
          data-snyk-test="Stepper: Continue"
          @click="nextStep"
          >Continue
        </BaseButton>
        <BaseButton
          v-if="previousStep"
          data-snyk-test="Stepper: Previous"
          @click="previousStep"
          >Previous
        </BaseButton>
      </div>
      <slot name="footer" />
    </BaseLayoutGap>
  </div>
</template>
<script setup lang="ts">
import BaseButton from '@patchui/productcl/components/BaseButton/BaseButton.vue';
import BaseLayoutGap from '@patchui/productcl/components/BaseLayoutGap/BaseLayoutGap.vue';
import { computed } from 'vue';

const selectionClasses = computed(() => {
  return {
    'selection--is-active': props.isActive,
    'selection--is-not-active': !props.isActive,
  };
});

export interface StepWrapperProps {
  isActive?: boolean;
  nextStep?: () => void;
  previousStep?: () => void;
  onSubmit?: () => void;
  canProgress?: boolean;
  submitInProgress?: boolean;
}
const props = defineProps<StepWrapperProps>();
</script>
<style lang="scss" scoped>
@import '~@/utils';
@import '~@/variables';
.step_wrapper {
  display: flex;
  align-items: flex-start;
}
.selection {
  &--is-active {
    @include typography('typography.product.default');
    margin-bottom: token('space.m');
  }
  &--is-not-active {
    @include typography('typography.product.default-small');
    color: token('color.ui.body');
    &:not(:empty)::before {
      content: '';
      display: inline-block;
      width: 3px;
      height: 3px;
      vertical-align: middle;
      background-color: token('color.ui.body');
      border-radius: 50%;
      margin-right: 8px;
    }
  }
}
</style>
