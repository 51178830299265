import { computed } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { authenticatedClient } from '../lib/apiClient';
import { HiddenApiResponse } from '../lib/utils/type-utilities';

import type { Ref } from 'vue';
import type { components } from './learn-backend-hidden-schema';

export type LessonAssignmentDecoratedResource =
  components['schemas']['LessonAssignmentDecoratedResource'];
export type LessonAssignmentRecordResource =
  components['schemas']['LessonAssignmentRecordResource'];

export const listOrgAssignments = async (orgId: string) => {
  const requestUrl = `/hidden/orgs/${orgId}/assignments?version=2024-03-03~experimental`;
  const response = await authenticatedClient.get<
    HiddenApiResponse<'listOrgAssignments'>
  >(requestUrl);
  return response.data.data;
};
const LIST_ORG_ASSIGNMENTS_BASE_KEY = 'listOrgAssignments';
export const useListOrgAssignments = (orgIdRef: Ref<string | undefined>) => {
  const enabled = computed(() => !!orgIdRef.value);
  const query = useQuery({
    queryKey: [LIST_ORG_ASSIGNMENTS_BASE_KEY, orgIdRef],
    enabled,
    queryFn: () => listOrgAssignments(orgIdRef.value as string),
    staleTime: 0,
    gcTime: 0,
    retry: false,
  });
  return query;
};

export const deleteOrgAssignments = async (
  orgId: string,
  assignmentIds: string[],
) => {
  const requestUrl = `/hidden/orgs/${orgId}/assignments?version=2024-03-03~experimental`;
  const body = {
    data: assignmentIds.map((id) => ({ id, type: 'lesson_assignment' })),
  };
  await authenticatedClient.delete<HiddenApiResponse<'deleteOrgAssignments'>>(
    requestUrl,
    {
      data: body,
    },
  );
};

export const createOrgAssignments = async (
  orgId: string,
  payload: components['schemas']['CreateAssignmentsRequest'],
) => {
  await authenticatedClient.post<HiddenApiResponse<'createOrgAssignments'>>(
    `/hidden/orgs/${orgId}/assignments?version=2024-03-03~experimental`,
    payload,
  );
};

export const listMyAssignments = async () => {
  const response = await authenticatedClient.get<
    HiddenApiResponse<'listMyAssignments'>
  >(`/hidden/self/assignments?version=2024-03-03~experimental`);
  return response.data.data;
};

export const useListMyAssignments = () => {
  return useQuery({
    queryKey: ['assignedLessons'],
    queryFn: listMyAssignments,
  });
};
