<template>
  <div class="certificate-block">
    <div class="certificate-block__header">
      <img alt="Learning Path Certificate Badge" :src="certificateImageUrl" />
    </div>
    <div class="certificate-block__content">
      <p class="certificate-block__question">
        {{ certificateName }}
      </p>
      <div v-if="!isUserLoggedIn">
        Sign up to track your progress!
        <div class="certificate-block__description__footer">
          <SnykAppCta
            text="Sign up for free"
            intent="signup"
            origin="sidebar"
            :is-full-width="true"
          />
        </div>
      </div>
      <div v-else-if="status === 'todo'" class="certificate-block__description">
        Start any lesson below and track your progress here!
      </div>
      <div
        v-else-if="status === 'inProgress'"
        class="certificate-block__description"
      >
        You're on your way to completing the learning path! Keep going!
        <div v-if="percentage" class="certificate-block__description__footer">
          <ProgressMeter
            :percentage="percentage"
            :variant="'learningPathCertificate'"
            :is-completed="false"
          />
        </div>
      </div>
      <div
        v-else-if="status === 'completed'"
        class="certificate-block__description"
      >
        🎉 Congratulations! You've completed the learning path!
        <div
          v-if="downloadAvailable"
          class="certificate-block__description__footer"
        >
          <Button
            v-if="componentStatus === 'start' || componentStatus === 'waiting'"
            variant="primary"
            :disabled="componentStatus === 'waiting'"
            :is-full-width="true"
            @click="downloadCertificate"
          >
            {{
              componentStatus === 'start'
                ? 'Download certificate'
                : 'Please wait...'
            }}
          </Button>
          <div v-else>
            Your certificate is ready! If download haven't started automatically
            <a
              id="certificate-pdf"
              target="__blank"
              :download="certificateFileName"
              >click here</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, nextTick } from 'vue';
import Button from '../../../../components/Button/Button.vue';
import ProgressMeter from '../../../../components/Card/ProgressMeter.vue';
import SnykAppCta from '../../../../components/Button/SnykAppCta.vue';
import makeCertificate from '../../certificate/makeCertificate';
import { useUser } from '../../../../hooks/useUser';
import itly from '../../../../lib/analytics/itly';
import isEmail from 'validator/lib/isEmail';

const { user } = useUser();
const userEmail = computed(() => user.value?.email);
const userName = computed(
  () => user.value?.name || user.value?.username || user.value?.email,
);

// Start = init state.
// Waiting = PDF creation in progress.
// Finished = PDF file is ready.
type Status = 'start' | 'waiting' | 'finished';

const componentStatus = ref<Status>('start');

interface ICertificateBlockProps {
  isUserLoggedIn: boolean;
  completionDate: Date;
  certificateName?: string;
  certificateImageUrl?: string;
  status?: string;
  downloadAvailable?: boolean;
  percentage?: number;
}

const props = withDefaults(defineProps<ICertificateBlockProps>(), {
  certificateName: 'Learning Path',
  certificateImageUrl:
    'https://res.cloudinary.com/snyk/image/upload/v1684156610/snyk-learn/learning-path-certificate-image-placeholder.svg',
  status: 'todo',
  downloadAvailable: false,
  isUserLoggedIn: false,
});

const certificateFileName = 'CertificateOfCompletion_' + props.certificateName;

async function downloadCertificate() {
  // send analytics event
  try {
    itly.certificateIsClicked({
      action: 'Download',
      eventSource: 'Learn',
      title: 'Download certificate',
      path: window.location.pathname,
    });
  } catch (e) {
    //TODO: capture with datadog frontend error monitoring
    window.console.error(JSON.stringify(e));
  }

  // Update UI to disable download button and update label to "please wait..."
  componentStatus.value = 'waiting';
  await nextTick();

  if (!userEmail.value || !userName.value) {
    // Must not happen, typescript safety throw
    throw Error('User details not available');
  }

  // Prepare blob
  const blob = await makeCertificate({
    learningPathTitle: props.certificateName,
    studentName: isEmail(userName.value)
      ? userName.value.split('@')[0]
      : userName.value,
    studentEmail: userEmail.value,
    date: props.completionDate,
  });

  // Create downloadable link with object url from blob
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = certificateFileName;
  link.click();

  // Update UI state
  componentStatus.value = 'finished';
  await nextTick();

  // Set a link for manual download
  const manualLink = document.getElementById(
    'certificate-pdf',
  ) as HTMLAnchorElement;
  manualLink.href = URL.createObjectURL(blob);
}
</script>

<style lang="scss" scoped>
@import '~@/utils';
@import '~@/variables';

.certificate-block {
  border-radius: token('radius.large');
  background-color: token('color.neutral.10');
  padding: token('space.m');
  padding-bottom: token('space.xl');

  &__header {
    display: flex;
    flex-direction: column;
    border: 1px solid token('color.brand.midnight');
    border-radius: token('radius.large');
    background: token('color.brand.white');
    margin-bottom: token('space.l');
    overflow: hidden;
  }

  &__question {
    @include typography('typography.brand.subhead-small');
    font-weight: 600;
  }

  &__title {
    @include typography('typography.brand.subhead-small');
  }

  &__description {
    @include typography('typography.product.default');
    line-height: rem(24px);

    &__footer {
      margin-top: token('space.m');
    }
  }
}
</style>
