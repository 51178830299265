export const DELAY_BEFORE_COMPLETING_STEP_IN_MS = 5000;
export const AUTH_OPTIONS = {
  POST_AUTH: 'auth=true',
  LOGOUT: 'logout=true',
};

export const VUEX_PERSISTENCE_KEY = 'vuex-learn-frontend';

export const MAIN_HEADER_HEIGHT_PX = 86; // should match css variable $mainHeaderHeight

export const LESSON_PAGE_NAME = 'DynamicLessonRoute';
export const MULTI_LESSON_PAGE_NAME = 'LessonRoute';
export const LEARNING_PATH_PAGE_NAME = 'LearningPathRoute';

export const STEP_ID_PREFIX = 'step-';
