<template>
  <BetaBanner />
  <main class="assignments-dashboard">
    <Breadcrumbs :breadcrumbs="breadcrumbs" />
    <div class="assignments-dashboard__title-row">
      <h1 class="assignments-dashboard__title">Assignments</h1>
      <router-link
        v-if="selectedOrg && !isLoading && !errorType"
        v-slot="{ navigate, href }"
        :to="newAssignmentsRoute(selectedOrg.id)"
        custom
      >
        <BaseButton
          variant="primary"
          size="medium"
          :href="href"
          @click="navigate"
        >
          Create new assignments
        </BaseButton>
      </router-link>
    </div>
    <p class="assignments-dashboard__description">
      Create new assignments for users with Snyk accounts
    </p>
    <p class="assignments-dashboard__description">
      Read more about this feature in
      <a
        href="https://docs.snyk.io/getting-started/snyk-learn/snyk-learn-reports-and-assignments#assignments"
        target="_blank"
        >Snyk Learn Assignments documentation
      </a>
    </p>
    <div class="assignments-dashboard__org-selector">
      <OrgSelector
        :org-id="props.orgId"
        @validOrgSelected="handleOrgSelected"
        @invalidOrgSelected="onInvalidOrgSelected"
      />
    </div>
    <Error v-if="errorType" :type="errorType" />
    <section v-else>
      <Table
        v-if="selectedOrg"
        :org-id="selectedOrg.id"
        :assignments="assignments || []"
        :assignments-loading="isLoading"
      />
      <NoOrgSelected v-else-if="!props.orgId" />
    </section>
  </main>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import { AxiosError } from 'axios';
import BaseButton from '@patchui/productcl/components/BaseButton/BaseButton.vue';
import OrgSelector, {
  InvalidOrgSelected,
} from '../../components/SearchableSelect/OrgSelector.vue';
import { components } from '../../api/learn-backend-v1-schema';
import { useListOrgAssignments } from '../../api/assignments';
import Error from './components/Error/Error.vue';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs.vue';
import type { Breadcrumbs as IBreadCrumbs } from '../../components/Breadcrumbs/breadcrumbs';
import itly from '../../lib/analytics/itly';
import Table from './components/Table/Table.vue';
import { selectedOrg, newAssignmentsRoute } from './assignmentUtils';
import NoOrgSelected from './components/NoOrgSelected/NoOrgSelected.vue';
import BetaBanner from '../Assignments/BetaBanner.vue';
import type { ErrorType } from './components/Error/errorContent';

interface DashboardProps {
  orgId?: string;
}

const props = defineProps<DashboardProps>();

const breadcrumbs: IBreadCrumbs = [
  [
    {
      icon: 'school-outline',
      label: 'Snyk Learn',
      url: '/',
      isActive: true,
    },
  ],
  [
    {
      label: 'Assignments',
      url: '/admin/assignments/',
      isActive: true,
    },
  ],
];

const errorType = ref<ErrorType | null>(null);

if (!props.orgId) {
  selectedOrg.value = undefined;
  errorType.value = null;
}

const handleOrgSelected = async (
  newOrg: components['schemas']['Organization'],
) => {
  const hasOrgChanged = !props.orgId || props.orgId !== newOrg.id;
  if (hasOrgChanged) {
    window.history.replaceState(
      {},
      document.title,
      `/admin/assignments/${newOrg.id}/`,
    );
  }
  selectedOrg.value = newOrg;
  errorType.value = null;
  itly.orgIsSelected({
    eventSource: 'Learn',
    orgId: newOrg.id,
    origin: 'lesson-assignments-dashboard',
    path: '/admin/assignments/',
  });
};

const onInvalidOrgSelected = (invalidOrgSelected: InvalidOrgSelected) => {
  window.history.replaceState(
    {},
    document.title,
    `/admin/assignments/${invalidOrgSelected.org.id}/`,
  );
  // This way we don't show table with assignments
  // And doesn't make a request to the backend
  selectedOrg.value = undefined;
  errorType.value = invalidOrgSelected.errorType;
};

const selectedOrgId = computed(() => selectedOrg.value?.id);

const {
  error: fetchAssignmentsError,
  data: assignments,
  isLoading,
} = useListOrgAssignments(selectedOrgId);

watch(fetchAssignmentsError, (e) => {
  if (e === null) return;
  if (e instanceof AxiosError && e.response?.status === 403) {
    errorType.value = 'insufficient_org_entitlements';
  } else {
    errorType.value = 'internal_error';
  }
});
</script>

<style lang="scss" scoped>
@import '~@/utils';
@import '~@/variables';

.assignments-dashboard {
  max-width: $pageMaxWidth;
  width: 100%;
  margin: 0 auto token('space.xxl') auto;
  padding: token('space.xxl') $mobileContainerPadding 0;

  @include media-query(small) {
    padding: token('space.xxl') $containerPadding 0;
    max-width: $pageMaxWidth;
  }

  &__title-row {
    display: flex;
    gap: token('space.layout.medium');
    justify-content: space-between;
    align-items: center;
    margin-bottom: token('space.xs');
  }

  &__title {
    font-family: token('typography.family.poppins');
    font-weight: 700;
    font-size: rem(32px);
    line-height: rem(40px);
    letter-spacing: -0.01em;
    color: token('color.neutral.90');
    @include media-query(small) {
      font-size: rem(48px);
      line-height: rem(64px);
    }
  }

  &__description {
    @include typography('typography.product.body-small');
    color: token('color.ui.body');
    margin-bottom: token('space.m');
    @include media-query(large) {
      margin-bottom: token('space.l');
    }
  }

  &__org-selector {
    &--error {
      align-items: center;
      display: flex;
      flex-direction: column;
      p {
        margin: 0;
      }

      p:first-of-type {
        @include typography('typography.product.heading3');
        color: token('color.ui.heading');
      }
      p:last-of-type {
        @include typography('typography.product.body-lead');
        color: token('color.ui.body');
      }
    }
  }
}
</style>
