import * as _ from 'lodash';
import { computed, ref, watch, Ref } from 'vue';
import { useQueryParam, useQueryParamArray } from '../../hooks/useQueryParam';
import {
  baseFilters,
  ecosystemsFilters,
  statusFilters,
  getTopicsFilters,
} from './filters';
import { watchDebounced } from '@vueuse/core';
import {
  EducationResource,
  useListEducationResources,
} from '../../api/educationResources';
import {
  escapeRegExp,
  haystackFilter,
  replaceSymbolsWithSpaces,
} from './haystackFilter';
import itly, {
  LearningFiltersAreChangedProperties,
} from '../../lib/analytics/itly';
import { useRouter } from 'vue-router';
import { components } from '../../api/learn-backend-hidden-schema';
import { useEducationProgress } from '../../api/educationProgress';
const MAX_SEARCH_QUERY_LENGTH = 50;
const DEBOUNCE_MS = 500;

export default function useCatalogQueryParams({
  topics,
}: {
  topics: Ref<string[]>;
}) {
  const router = useRouter();
  const [searchQueryParam, updateSearchQueryParam] = useQueryParam('q');
  const searchQuery = ref('');

  watch(searchQueryParam, (newVal) => {
    searchQuery.value = newVal?.substring(0, MAX_SEARCH_QUERY_LENGTH) ?? '';
  });

  watchDebounced(
    searchQuery,
    () => {
      updateSearchQueryParam(
        searchQuery.value.substring(0, MAX_SEARCH_QUERY_LENGTH),
      );
      itly.lessonsSearchIsChanged({
        origin: 'catalog',
        searchTerm: searchQuery.value,
        eventSource: 'Learn',
      });
    },
    { debounce: DEBOUNCE_MS },
  );

  const [typeQueryParam, updateTypeQueryParam] = useQueryParamArray('type', {
    validator: (val) => {
      return baseFilters['Type']['options']
        .map((o) => o.value)
        .includes(val as any);
    },
  });

  const [formatQueryParam, updateFormatQueryParam] = useQueryParamArray(
    'format',
    {
      validator: (val) => {
        return baseFilters['Format']['options']
          .map((o) => o.value)
          .includes(val as any);
      },
    },
  );

  const [categoriesQueryParam, updateCategoriesQueryParam] = useQueryParamArray(
    'categories',
    {
      validator: (val) => {
        return ecosystemsFilters['Categories']['options']
          .map((o) => o.value)
          .includes(val as any);
      },
    },
  );

  const [topicsQueryParam, updateTopicsQueryParam] = useQueryParamArray(
    'topics',
    {
      validator: (val) => {
        return getTopicsFilters(topics.value)
          ['Topics']['options'].map((o) => o.value)
          .includes(val as any);
      },
    },
  );

  const [statusQueryParam, updateStatusQueryParam] = useQueryParamArray(
    'status',
    {
      validator: (val) => {
        return statusFilters['Status']['options']
          .map((o) => o.value)
          .includes(val as any);
      },
    },
  );

  const { data: educationResources } = useListEducationResources();

  const { data: educationProgress } = useEducationProgress();

  const filterByQueryParam = (
    educationResource: EducationResource,
    type: string,
  ) => {
    const ecosystemTag = educationResource.attributes.tags.find(
      (tag) => tag.tag_type === 'ecosystem',
    );
    const topicTag = educationResource.attributes.tags.find(
      (tag) => tag.tag_type === 'topic',
    );
    const educationProgressItem = educationProgress.value?.find(
      (educationProgress: components['schemas']['EducationProgress']) =>
        educationProgress.relationships['education_resource']['data']['id'] ===
        educationResource.id,
    );
    const status = educationProgressItem?.attributes.status || 'todo';
    switch (type) {
      case 'Type':
        return typeQueryParam.value?.includes(
          educationResource.attributes.education_content_category
            .replace(' ', '-')
            .toLowerCase(),
        );
      case 'Format':
        return formatQueryParam.value?.includes(educationResource.type);
      case 'Categories':
        if (ecosystemTag === undefined) return false;
        return (
          categoriesQueryParam.value?.some((category) =>
            ecosystemTag.tag_values.includes(category),
          ) ?? false
        );
      case 'Topics':
        if (topicTag === undefined) return false;
        return (
          topicsQueryParam.value?.some((topic) =>
            topicTag.tag_values.includes(topic),
          ) ?? false
        );
      case 'Status':
        return statusQueryParam.value?.includes(status) ?? false;
      default:
        return false;
    }
  };

  const filteredEducationResources = computed(() => {
    let resources: EducationResource[] = educationResources.value || [];
    if (searchQuery.value) {
      // Regex on a haystack probably isn't the best way to do this, but gives a nice
      // amount of flexibility until we need something more complex.
      const searchTextWithoutSymbols = replaceSymbolsWithSpaces(
        searchQuery.value,
      );
      const searchRegex = new RegExp(
        escapeRegExp(searchTextWithoutSymbols),
        'igm',
      );
      resources = resources.filter((educationResource: EducationResource) =>
        haystackFilter(educationResource, searchRegex),
      );
    }

    const filters = {
      Type: {
        param: typeQueryParam,
        options: baseFilters['Type']['options'],
      },
      Format: {
        param: formatQueryParam,
        options: baseFilters['Format']['options'],
      },
      Categories: {
        param: categoriesQueryParam,
        options: ecosystemsFilters['Categories']['options'],
      },
      Topics: {
        param: topicsQueryParam,
      },
      Status: {
        param: statusQueryParam,
        options: statusFilters['Status']['options'],
      },
    };
    for (const [key, value] of Object.entries(filters)) {
      const { param } = value;
      if (param.value && param.value?.length > 0) {
        resources = resources.filter((res) => filterByQueryParam(res, key));
      }
    }
    return resources;
  });

  const selectedFilters = computed<{ [key: string]: string[] }>(() => {
    return {
      Type: typeQueryParam.value || [],
      Format: formatQueryParam.value || [],
      Categories: categoriesQueryParam.value || [],
      Topics: topicsQueryParam.value || [],
      Status: statusQueryParam.value || [],
    };
  });

  const handleFiltersUpdate = (updatedFilters: { [key: string]: string[] }) => {
    const filterActions: {
      [key: string]: (value: string[] | null) => Promise<void>;
    } = {
      Type: updateTypeQueryParam,
      Format: updateFormatQueryParam,
      Categories: updateCategoriesQueryParam,
      Topics: updateTopicsQueryParam,
      Status: updateStatusQueryParam,
    };

    const routerReplaceQuery: { [key: string]: string } = {};

    for (const [key, updateFunction] of Object.entries(filterActions)) {
      const selectedValues = selectedFilters.value[key] || [];
      const updatedValues = updatedFilters[key] || [];

      if (!_.isEqual(selectedValues, updatedValues)) {
        if (updatedValues.length > 0) {
          routerReplaceQuery[key.toLowerCase()] = updatedValues.join(',');
        }
        updateFunction(updatedValues);

        const category =
          key === 'Topics'
            ? 'topic'
            : (key.toLowerCase() as LearningFiltersAreChangedProperties['category']);

        let learningType: LearningFiltersAreChangedProperties['learningType'] =
          'all';

        if (
          selectedFilters.value &&
          selectedFilters.value['Type'] &&
          selectedFilters.value['Type'].length
        ) {
          learningType =
            selectedFilters.value['Type'][0] === 'security-education'
              ? 'security education'
              : 'product training';
        }

        itly.learningFiltersAreChanged({
          origin: 'catalog',
          selectedValue: updatedValues[updatedValues.length - 1] || 'nothing',
          filterAction:
            updatedValues.length > selectedValues.length
              ? 'selected'
              : 'deselected',
          category: category,
          learningType: learningType,
        });
      } else {
        if (selectedValues.length > 0) {
          routerReplaceQuery[key.toLowerCase()] = selectedValues.join(',');
        }
      }
    }

    if (
      router.currentRoute.value.path.includes('security-education') ||
      router.currentRoute.value.path.includes('product-training')
    ) {
      router.replace({
        path: '/catalog/',
        query: routerReplaceQuery,
        hash: router.currentRoute.value.hash,
      });
    }
  };

  return {
    searchQuery,
    updateSearchQueryParam,
    typeQueryParam,
    updateTypeQueryParam,
    formatQueryParam,
    updateFormatQueryParam,
    categoriesQueryParam,
    updateCategoriesQueryParam,
    topicsQueryParam,
    updateTopicsQueryParam,
    statusQueryParam,
    updateStatusQueryParam,
    filteredEducationResources,
    handleFiltersUpdate,
    selectedFilters,
  };
}
