<template>
  <BrandPrimaryHeader
    :mobile-nav-open="isHeaderMobileNavOpen"
    @mobile-nav-opened="isHeaderMobileNavOpen = true"
    @mobile-nav-closed="isHeaderMobileNavOpen = false"
  >
    <template #logo>
      <BrandLogo sub-brand="Learn" sub-brand-href="/" />
    </template>
    <template #navigationList>
      <BrandHeaderDisclosureNav
        wrapper="li"
        title="Browse topics"
        data-snyk-test="LearnNav"
        @click="onLearnNavClick"
      >
        <template #contents>
          <BrandHeaderNavItemList
            title="By type"
            :nav-links="catalogByTopicItems"
          />
          <BrandHeaderNavItemList
            title="By format"
            :nav-links="catalogByFormatItems"
          />
        </template>
        <template #cta>
          <BrandNavCTAButton href="/catalog/"> View all </BrandNavCTAButton>
        </template>
      </BrandHeaderDisclosureNav>
      <li v-if="isUserLoggedIn">
        <router-link
          v-slot="{ navigate, href, isActive }"
          to="/user/learning-progress/"
          custom
        >
          <BrandHeaderNavLink
            title="Learning progress"
            :href="href"
            :selected="isActive"
            @click="(e: MouseEvent) => {
              navigate(e);
              closeMobileNav();
            }"
          />
        </router-link>
      </li>
    </template>
    <template #actions="{ renderingContext }">
      <AccountPanel
        v-if="renderingContext !== 'mobile' && user && isUserLoggedIn"
        :user="user"
        @user-menu-nav-link-clicked="closeMobileNav"
      />
      <div v-show="!isUserLoading && !user">
        <TenantSelector data-snyk-test="TenantSelector" />
      </div>
      <div v-show="!isUserLoading && !user">
        <BrandButton
          :href="signUpHref"
          wrapper="a"
          variant="primary"
          :disabled="isSignUpLoading || isLoginLoading"
          @click="(e: KeyboardEvent | MouseEvent) => beforeNavigationChange(handleSignUpClick)(e)"
        >
          <BaseLoadingSpinner v-if="isSignUpLoading" size="medium" />
          <span v-else>{{ signUpButtonText }}</span>
        </BrandButton>
      </div>
    </template>
    <template #mobileNavExtras>
      <div :id="headerTableOfContentsTeleportId"></div>
    </template>
    <template #mobileNavTriggers>
      <AccountPanel
        v-if="user && isUserLoggedIn"
        :user="user"
        @user-menu-nav-link-clicked="closeMobileNav"
      />
    </template>
  </BrandPrimaryHeader>
</template>

<script lang="ts" setup>
import { ref, computed } from 'vue';
import {
  BrandPrimaryHeader,
  BrandLogo,
  BrandHeaderNavLink,
  BrandButton,
  BrandHeaderDisclosureNav,
  BrandHeaderNavItemList,
  BrandNavCTAButton,
} from '@patchui/ecosystemcl';
import BaseLoadingSpinner from '@patchui/productcl/components/BaseLoadingSpinner/BaseLoadingSpinner.vue';
import { beforeNavigationChange } from '../../lib/utils/navigation';
import AccountPanel from './components/AccountPanel/AccountPanel.vue';
import { MobileMenuState } from './Header.interfaces';
import { useUser } from '../../hooks/useUser';
import { useSnykAppCTA } from '../../hooks/useSnykAppCTA';
import itly from '../../lib/analytics/itly';
import {
  headerTableOfContentsTeleportId,
  isHeaderMobileNavOpen,
} from './headerStore';
import TenantSelector from './components/TenantSelector/TenantSelector.vue';

export interface IHeaderProps {
  defaultMobileMenuState?: MobileMenuState;
}

const isLoginLoading = ref(false);
const isSignUpLoading = ref(false);

const { user, isLoading: isUserLoading } = useUser();
const isUserLoggedIn = computed(() => !!(user.value && !isUserLoading.value));
const closeMobileNav = () => {
  isHeaderMobileNavOpen.value = false;
};

const signUpButtonText = 'Sign up';
const { href: signUpHref, trackClick: trackSignUpClick } = useSnykAppCTA({
  intent: 'signup',
  origin: 'nav',
  title: signUpButtonText,
});
const handleSignUpClick = async () => {
  isSignUpLoading.value = true;
  await trackSignUpClick();
};

const onLearnNavClick = (event: MouseEvent) => {
  const text = (event.target as HTMLDivElement)?.textContent?.trim();
  const allowList = [
    'Security education',
    'Product training',
    'Learning path',
    'Lesson',
    'View all',
  ];
  if (!text || !allowList.includes(text)) return;

  itly.learnNavIsClicked({
    eventSource: 'Learn',
    origin: 'nav',
    category: text,
  });
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const catalogByTopicItems = [
  {
    type: 'link',
    title: 'Security education',
    href: '/catalog/security-education',
  },
  {
    type: 'link',
    title: 'Product training',
    href: '/catalog/product-training',
  },
];

const catalogByFormatItems = [
  {
    type: 'link',
    title: 'Lesson',
    href: '/catalog/?format=lesson',
  },
  {
    type: 'link',
    title: 'Learning path',
    href: '/catalog/?format=learning_path',
  },
];
</script>

<style lang="scss" scoped>
@import '~@/utils';
@import '~@/variables';

:deep(.brand-nav-cta-button) {
  background: token('color.ui.canvas');
}
:deep(.brand-primary-header__actions-mobile) {
  margin-top: 0;
}

:deep(.brand-disclosure-nav, .brand-button--primary) {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

:deep(.brand-logo__sub-brand) {
  font-size: 20px;
  margin-bottom: 6px;
}
</style>
