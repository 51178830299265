<template>
  <!--  TODO: Uncomment when a future startDate is supported -->
  <!--  <BaseDatePicker-->
  <!--    v-model="startDate"-->
  <!--    :disabled-dates="disabledStartDates"-->
  <!--    :clearable="false"-->
  <!--    highlight-disabled-days-->
  <!--    :teleport="true"-->
  <!--    label="Optionally select a start date"-->
  <!--  />-->
  <BaseDatePicker
    v-model="dueDate"
    :disabled-dates="disabledDueDates"
    placeholder="No due date"
    :highlight="{ options: { highlightDisabled: true } }"
    :teleport="true"
    label="Optionally select a future due date"
    :auto-apply="false"
    @update:modelValue="validateDueDate"
  />
</template>
<script lang="ts" setup>
import BaseDatePicker from '@patchui/productcl/components/BaseDatePicker/BaseDatePicker.vue';
import { dueDate } from './newAssignmentsStore';
import { isValidAssignmentDueDate } from './assignmentDateValidator';

// TODO: Uncomment when a future startDate is supported
// const disabledStartDates = (date: Date | undefined) =>
//   !isValidAssignmentStartDate(date);
const disabledDueDates = (date: Date | undefined) =>
  !isValidAssignmentDueDate(date);

const validateDueDate = (output: number | number[] | undefined): void => {
  if (typeof output === 'number') {
    if (isValidAssignmentDueDate(new Date(output))) {
      dueDate.value = output;
    } else {
      dueDate.value = null;
    }
  } else {
    dueDate.value = null;
  }
};
</script>
<style lang="scss" scoped>
@import '~@/utils';
@import '~@/variables';

::v-deep(.icon) {
  vertical-align: inherit;
}
</style>
