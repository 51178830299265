import { unauthenticatedClient } from '../lib/apiClient';
import { isContentPreviewActive } from '../lib/utils/isContentPreview';
import type { components } from './learn-backend-hidden-schema';
import type { HiddenApiResponse } from '../lib/utils/type-utilities';
import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/vue-query';
import { QueryMetaExtended } from '../types';

export const LIST_EDUCATION_RESOURCES_KEY = 'listEducationResources';

// When updating the version below please remember to update the version in generate-sitemap.ts
export const LIST_EDUCATION_RESOURCES_VERSION = '2024-03-03~experimental';
export const PREVIEW_EDUCATION_RESOURCES_VERSION = '2024-03-03~experimental';

export const listEducationResources = async (): Promise<
  EducationResource[]
> => {
  let requestUrl = `/hidden/education_resources?version=${LIST_EDUCATION_RESOURCES_VERSION}`;
  if (isContentPreviewActive()) {
    requestUrl = `/hidden/preview_education_resources?version=${PREVIEW_EDUCATION_RESOURCES_VERSION}&content_source=source-preview`;
  }
  const response = await unauthenticatedClient.get<
    HiddenApiResponse<'listEducationResources'>
  >(requestUrl);
  return response.data.data;
};

export type EducationResource = components['schemas']['EducationResource'];

export const useListEducationResources = () => {
  return useQuery<EducationResource[], AxiosError>({
    queryKey: [LIST_EDUCATION_RESOURCES_KEY],
    queryFn: listEducationResources,
    staleTime: Infinity,
    meta: {
      isContentQuery: true,
    } as QueryMetaExtended,
  });
};
